
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIArbitraryTimePicker from "@/views/resources/documentation/element-ui/form/time-picker/ArbitraryTimePicker.vue";
import EUIArbitraryTimeRange from "@/views/resources/documentation/element-ui/form/time-picker/ArbitraryTimeRange.vue";

export default defineComponent({
  name: "time-picker",
  components: {
    EUIArbitraryTimePicker,
    EUIArbitraryTimeRange
  },
  setup() {
    setCurrentPageTitle("TimePicker");
  }
});
